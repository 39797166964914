<template>
  <div class="add-worker-form container">
    <div class="row" style="margin-top: 50px;">
      <div class="col-md-12">
        <div class="card-custom">
          <form>
            <div class="header-form text-center"><h3>{{ ((id === null || id === undefined)? 'New Worker:  ' : 'Worker :   ')}}
              <img class="avatar-header" :src="avatarLink"> {{name}}</h3></div>
            <div class="container modal-body">
              <div class="row text-center">
                <div class="col-md-4">
                  <div class="col-md-12">
                    <img class="full-size" width="200" height="200" :src="fullSizeLink" style="border-radius: 10%; border: 1px solid #cccccc"/>
                  </div>
                  <div class="form-group col-md-12">
                    <div class="" style="margin-top: 10px;">

                      <label class="custom-file-input btn btn-warning" for="files" />
                      <input id="files" type="file" multiple="multiple" name="PhotosForm[files]"  ref="files" accept="image/*" style="visibility: hidden;" v-on:change="handleFileUploads">
                    </div>
                  </div>
                </div>
                <div class="row col-md-8">
                  <div class="col-md-9">
                    <label class="control-label" >Contact Name </label>
                    <input type="text"  class="form-control" v-model="name" >
                  </div>
                  <div class="col-md-3">
                    <label class="control-label" >Mobile App</label>
                    <Multiselect
                        v-model='type'
                        placeholder='Need or not...'
                        label='name'
                        valueProp='id'
                        trackBy='name'
                        :options="types"
                    >
                    </Multiselect>
                  </div>
                  <div class="col-md-4">
                    <label class="control-label" >Date of Birth </label>
                    <flat-pickr v-model="birthDay" style="background-color: white"
                                :config="configBirthday"
                                class="form-control"
                                placeholder="dd.mm.yyyy"
                    >
                    </flat-pickr>
                  </div>
                  <div class="col-md-4">
                    <label class="control-label" >Phone </label>
                    <input type="text"  class="form-control" v-model="phone">
                  </div>
                  <div class="col-md-4">
                    <label class="control-label" >E-mail </label>
                    <input type="email"  class="form-control" v-model="email" @keyup='checkEmail()'>
                    <small id="emailHelpBlock" class="form-text text-muted text-danger" v-if="!emailIsOk">
                      Email is already exist.  Choose another one, please.
                    </small>
                  </div>
                  <div class="col-md-6"  v-show="((id === null || id === undefined))">
                    <label class="control-label" >Login (username)</label>
                    <input type="text"  class="form-control" v-model="username" required @keyup='checkUsername()'>
                    <small id="usernameHelpBlock" class="form-text text-muted text-danger" v-if="!usernameIsOk">
                      Username is already exist.  Choose another one, please.
                    </small>
                  </div>
                  <div class="col-md-6"  v-show="(!(id === null || id === undefined))">
                    <label class="control-label" >Login (username)</label>
                    <input type="text" readonly class="form-control" v-model="username" required>
                  </div>
                  <div class="col-md-6">
                    <label class="control-label" >Password </label>
                    <input type="password"  class="form-control" v-model="password" required>
                  </div>
                </div>

              </div>
              <hr/>
              <div class="row text-center">
                <div class="form-group col-md-6">
                  <div class="">
                    <label class="control-label">Department</label>
                    <Multiselect
                        v-model='departmentId'
                        placeholder='Select department...'
                        :searchable='true'
                        label='name'
                        valueProp='id'
                        trackBy='name'
                        :options='departments'
                    >
                    </Multiselect>
                    <!--              <p class="help-block help-block-error"></p>-->
                  </div>
                  <div class="">
                    <label class="control-label">Team</label>
                    <Multiselect
                        v-model='teamId'
                        mode="tags"
                        placeholder='Select team(s)...'
                        label='name'
                        trackBy="name"
                        valueProp="id"
                        :searchable='true'
                        :options='teams'>
                    </Multiselect>
                  </div>
                  <div class="">
                    <label class="control-label" >Current Sleeping Place  </label>
                    <!--                      <a title="Reset Worker sleeping position" @click="checkin"><span class="custom-label warning" v-show="(!(id === null || id === undefined))">set new position</span></a>-->
                    <a title="Reset Worker sleeping position" @click="checkin"><span class="badge warning" style="margin-left: 5px; cursor:pointer" v-show="(!(id === null || id === undefined))">set new position</span></a>
                    <div class="input-group">
                      <a class="input-group-text" title="Search address on Map" @click="searchAdressOnMap">
                        <i class="fas fa-map-marker-alt fa-lg" aria-hidden="true"></i>
                      </a>
                      <input type="text" class="form-control" placeholder="Type address here... and click search" v-model="sleepingPlace" >
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-6">
                      <label class="control-label">Latitude</label>
                      <input type="text" class="form-control" v-model="latitude">
                    </div>
                    <div class="col-md-6">
                      <label class="control-label">Longitude</label>
                      <input type="text" class="form-control" v-model="longitude">
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div id="sleeping-place" ref="mapDivRef"></div>
                </div>
              </div>
            </div>
            <div class="footer-form">
              <div class="row">
                <div class="col-md-12">
                  <input type="reset" v-show="((id === null || id === undefined))" value="&#x2704; Clear form"  class="btn btn-warning" style="margin-right:10px">
                  <input v-if="canSubmit" type="button" class="btn btn-primary" v-show="((id === null || id === undefined))" value="&#10011; Add new Worker"  @click="addWorker">
                  <input v-if="!canSubmit" type="button" class="btn btn-primary disabled" v-show="((id === null || id === undefined))" value="&#10011; Check username and email" >
                  <input v-if="canSubmit" type="button" class="btn btn-primary"  v-show="!((id === null || id === undefined))" value="&#10001; Update"  @click="updateWorker">
                  <input v-if="!canSubmit" type="button" class="btn btn-primary disabled"  v-show="!((id === null || id === undefined))" value="&#10001; Change email, please" >
                </div>
              </div>
            </div>

          </form>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "@/store";
import {computed, onMounted, reactive, ref, toRefs} from "vue";
import {ActionTypes} from "@/store/actions";
import axios from "axios";
import {MutationType} from "@/store/mutations";
import Multiselect from '@vueform/multiselect'
import flatPickr from "vue-flatpickr-component";
import workerService from "@/services/workerService";
import router from "@/router";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import locationService from "@/services/locations.service";
import chatService from "@/services/chatService";
import authService from "@/services/auth.service";
export default {
  name: "MapContent",
  setup(props) {
    const store = useStore();
    // the mapbox map object
    const map = ref(null);
    // the map element in the templste
    const mapDivRef = ref(null);
    const workerID = ref(props.id)
    const files = ref([])
    const state = reactive ({
      id:null,
      accountId:294,
      fullSizeLink:"https://icon-library.com/images/profile-photo-icon/profile-photo-icon-7.jpg",
      avatarLink:"https://extranet.ronabooking.com/Images/no_user.jpg",
      teamId:[],
      name:"",
      birthDay:"25.01.2021",
      phone:"",
      email:"",
      oldEmail:"",
      username:"",
      password:"",
      latitude:45.3148,
      longitude:14.2873,
      departmentId: 5,
      sleepingPlace:"Opatija, Nova Cesta 119",
      type:"mobile",
      roles:['agent'],
      status:"",
      files:[],
      route:"",
      currentPosition:"",
    })
    const departments = computed(() => store.getters.departments);
    const teams = computed(() =>store.getters.teams);
    const searchAdressOnMap = () => {
      const url = "https://maps.googleapis.com/maps/api/geocode/json?address="+ state.sleepingPlace + "&key=AIzaSyCuVxfF41Hk2T6mwb2eR8V42PBUV7JdWL4";
      axios.get(url).then((response) => {
        if(response.status===200) {
          state.latitude = response.data.results[0].geometry.location.lat;
          state.longitude = response.data.results[0].geometry.location.lng;
          state.address = response.data.results[0].formatted_address;
        }
      });
    };
    const fetchAdress = () =>  {
      const url = "https://maps.googleapis.com/maps/api/geocode/json?latlng="+ this.longitude+","+ this.latitude +"&key=AIzaSyCuVxfF41Hk2T6mwb2eR8V42PBUV7JdWL4";
      console.log(url)
      axios.get(url).then((response) => {
        if(response.status===200) {
          const lon = response.data.results[0].geometry.location.lng;
          const lat = response.data.results[0].geometry.location.lat;
          const formattedAddress = response.data.results[0].formatted_address;
          state.latitude = lat;
          state.longitude = lon;
          state.sleepingPlace = formattedAddress
        }
      });
    };
    const canSubmit = ref(props.id !== undefined && state.email === state.oldEmail);
    const emailIsOk = ref(true);
    const usernameIsOk = ref(true);
    const  checkEmail = async () => {
       if (props.id !== undefined && state.email === state.oldEmail) {
         canSubmit.value = true;
         emailIsOk.value = true;
         return true;
       }
       authService.checkEmail(state.email).then((data)=>{
        if (!data.success)
        {
          emailIsOk.value = false;
          canSubmit.value = false;
          return false;
        }
         canSubmit.value = !!(usernameIsOk.value);
         emailIsOk.value = true;
        return true;
      });
    }
    const  checkUsername = async () => {
       authService.checkUsername(state.username).then((data)=>{
        if (!data.success) {
          usernameIsOk.value = false;
          canSubmit.value = false;
          return false;
        }
         canSubmit.value = !!(emailIsOk.value);
         usernameIsOk.value = true;
        return true;
      });
    }
    const addWorker = async () => {
      console.log("adding worker");
      debugger
      // await checkEmail().then ( async () => {
      //   await checkUsername().then( async () => {
      //     if ( !usernameIsOk || !emailIsOk)
      //       return false;
          store.dispatch(ActionTypes.createWorker,{
            sleepingPlace: state.sleepingPlace,
            latitude: state.latitude,
            longitude: state.longitude,
            role: state.roles,
            account_id : state.accountId,
            department_id : state.departmentId,
            team_id : state.teamId,
            email : state.email,
            birthday : state.birthDay,
            contact_tel : state.phone,
            contact_name : state.name,
            password : state.password,
            username : state.username,
            type : "mobile",
            files: []// state.files
          });
           router.push('/tickets');
      // })
    // });

    }
    const updateWorker = () => {
      console.log("updating worker");
      store.dispatch(ActionTypes.updateWorker,{
        id: state.id,
        sleepingPlace: state.sleepingPlace,
        latitude: state.latitude,
        longitude: state.longitude,
        role: state.roles,
        account_id : state.accountId,
        department_id : state.departmentId,
        team_id : state.teamId,
        email : state.email,
        birthday : state.birthDay,
        contact_tel : state.phone,
        contact_name : state.name,
        password : state.password,
        username : state.username,
        type : "mobile",
        files: []// state.files
      });
      router.push('/tickets');
    }
    const setupFields = (worker) => {
      if (props.id!== null && props.id !== undefined) {
        // const worker = worker? worker: store.getters.getWorkerById(Number(props.id))
        if (worker !==null) {
          state.id = props.id ;
          state.accountId = worker.accountId ;
          state.teamId = worker.teamId ;
          state.name = worker.name ;
          // state.birthDay = new Date(worker.birthDayString) ;
          // state.birthDay = new Date(worker.birthday) ;
          state.birthDay = worker.birthDayString;
          state.status = worker.status ;
          state.phone = worker.phone ;
          state.email = worker.email ;
          state.oldEmail = worker.email,
          state.username = worker.username ;
          state.password = worker.password ;
          state.sleepingPlace = worker.sleepingPlace ;
          state.latitude = (worker.sleepingPoint)?worker.sleepingPoint.coordinates[1]:45.3148;
          state.longitude = (worker.sleepingPoint)?worker.sleepingPoint.coordinates[0]:14.2873;
          state.departmentId = worker.departmentId ;
          state.type = "mobile" ;
          state.roles = worker.roles ;
          state.files = [worker.fullSizeLink] ;
          state.fullSizeLink = worker.fullSizeLink ; //"https://icon-library.com/images/profile-photo-icon/profile-photo-icon-7.jpg";
          state.avatarLink = worker.avatarLink ; //"https://extranet.ronabooking.com/Images/no_user.jpg";
        }
      }
    }
    const handleFileUploads = (event) => {
      debugger
      const fieldName = event.target.name;
      const fileList = event.target.files;
      if (!fileList.length) return;
      const formData = new FormData();
      // append the files to FormData
      Array
          .from(Array(fileList.length).keys())
          .map(x => {
            formData.append(fieldName, fileList[x], fileList[x].name);
          });
      workerService.setAvatar(formData, props.id).then((response)=> {
        if (response.data.success) {
          state.avatarLink = response.data.avatar;
          state.fullSizeLink = response.data.fullSizeLink;
        }
      })
    }
    const checkin =()=> {
      workerService.checkIn({
        latitude: state.latitude,
        longitude: state.longitude,
        address: state.sleepingPlace,
        id: props.id,
        department_id : state.departmentId,
        team_id : state.teamId,
      })
    }

    onMounted(async () => {
      let  centerMapLatitude = 45.3148;
      let  centerMapLongitude = 14.2873;
      await store.dispatch(ActionTypes.fetchTeams, null);
      if (props.id !== null && props.id !== undefined) {
        workerService.getWorker(props.id).then((response) => {
          if (response.status === 200) {
            store.commit(MutationType.getWorker, response.data)
            setupFields(response.data)
            centerMapLatitude = (response.data.sleepingPoint)?response.data.sleepingPoint.coordinates[1]:45.3148;
            centerMapLongitude = (response.data.sleepingPoint)?response.data.sleepingPoint.coordinates[0]:14.2873;
          }
        })
        locationService.route(props.id).then((response)=> {
          state.route =  response.data;
        })
        locationService.currentPosition(props.id).then((response)=> {
          state.currentPosition =  response.data;
        })
      }
      else {
        state.latitude = 45.3148;
        state.longitude = 14.2873;
        state.sleepingPlace = "Opatija, Nova Cesta 119"
        state.departmentId = 5
        state.type = "mobile"
        state.fullSizeLink = "https://icon-library.com/images/profile-photo-icon/profile-photo-icon-7.jpg";
        // state.fullSizeLink = "https://extranet.ronabooking.com/Images/no_user.jpg";
        state.avatarLink = "https://extranet.ronabooking.com/Images/no_user.jpg";
      }
      mapboxgl.accessToken = "pk.eyJ1IjoiaG9zdGEiLCJhIjoiY2pzYzAzYTVzMGc2cDQzbXV0YnVvdms2NSJ9.8_IUy4eQ7Ctcl3ImkHvmZw";
      const map = new mapboxgl.Map({
        container: "sleeping-place",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [centerMapLongitude, centerMapLatitude],
        zoom: 12
      });
      map.on('load', () => {
        map.scrollZoom.enable();
        map.addControl(new mapboxgl.NavigationControl());
        map.addControl(new mapboxgl.ScaleControl({position: 'bottom-left'}));
        const marker = new mapboxgl.Marker({draggable: false})
            .setLngLat([state.longitude, state.latitude])
            .setPopup(new mapboxgl.Popup({offset: 25})
                .setHTML('<div>' + state.address + '</div>'))
            .addTo(map);
        map.flyTo({
          center: [state.longitude, state.latitude],
          zoom: 15
        })
        if (state.route !=="") {
          //last 1 week route
          state.route.data.features.forEach((feature, index)=> {
            if (feature.geometry.type==="Point") {
              const popup = new mapboxgl.Popup({offset: 25}).setHTML('<div>' + feature.properties.time +'<br><hr>'+feature.properties.worker + '</div>');
              // add marker to map
              new mapboxgl.Marker({draggable: false,color:`rgb(255, 0, 255)`})
                  .setLngLat(feature.geometry.coordinates).setPopup(popup)
                  .addTo(map);
            }
            if (feature.geometry.type==="LineString"){
              // add track to map
              map.addSource('track', {'type': 'geojson', 'data': feature});
              map.addLayer({
                'id': 'track',
                'type': 'line',
                'source': 'track',
                'layout': {
                  'line-join': 'round',
                  'line-cap': 'round'
                },
                'paint': {
                  'line-color': '#3A4CA6',
                  'line-width': 8
                }
              });
            }
          })
          map.addSource('points',state.route);
          map.addLayer({
            'id': 'user-track',
            'type': 'symbol',
            'source': 'points',
          });
        }
        //current position maker
        if (state.currentPosition !=="" ) {
          const el = document.createElement('div');
          el.className = 'avatar-header';
          el.style.backgroundImage = "url("+state.currentPosition.properties.avatarLink+")";
          el.style.width =  '40px';
          el.style.height = '40px';
          const popup = new mapboxgl.Popup({offset: 25}).setHTML('<div>' + state.currentPosition.name + '</div>');
          // add marker to map
          new mapboxgl.Marker(el, { draggable: false,offset: [0, 0]})
              .setLngLat(state.currentPosition.geometry.coordinates).setPopup(popup)
              .addTo(map);
          map.flyTo({
            center: state.currentPosition.geometry.coordinates,
            zoom: 15
          })
        }
      })
    })
    return {mapDivRef, addWorker, updateWorker, checkin, fetchAdress ,checkEmail, checkUsername, emailIsOk, usernameIsOk, canSubmit, handleFileUploads, files, departments, teams, searchAdressOnMap, ...toRefs(state)}
  },
  components: { Multiselect, flatPickr,
    // JqxFileUpload,
    // handyUploader
  },
  data () {
    return {
      handyAttachments: [],
      types: [
        // {id:'receptionist',name:'Receptionist'},
        // {id:'manager',name:'Manager'},
        // {id:'agent',name:'Worker'},
        // {id:'partner',name:'Partner'},
        // {id:'owner',name:"Owner"},
        {id:'mobile',name:'Mobile'},
        {id:'desktop',name:'Desktop'},
      ],
      configBirthday : {
        allowInput: true,
        wrap: true, // set wrap to true only when using 'input-group'
        dateFormat: 'd.m.Y',
      }
    }
  },
  props: {
    id: {
      type: Number,
    },
  }
}
</script>

<style scoped>
#sleeping-place {
  width: 100%;
  height: 90px;
  position: relative;
  top: 0;
  bottom: 0;
  min-height: 270px;
}
.map-container {
  position: relative;
  color: #404040;
  font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', Sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Choose photo(s)';
  display: inline-block;
  /*background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);*/
  /*border: 1px solid #999;*/
  /*border-radius: 3px;*/
  /*padding: 5px 8px;*/
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  /*text-shadow: 1px 1px #fff;*/
  /*font-weight: 700;*/
  /*font-size: 10pt;*/
}
.custom-file-input:hover::before {
  /*border-color: black;*/
}
.custom-file-input:active::before {
  /*background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);*/
}
</style>